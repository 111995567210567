import styled from 'styled-components';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getFilterOptions, getFooterLinks } from '../new-lib/apis';
import Button from '../new-components/ui/Button';
import router from 'next/router';
import FooterContainer from '../new-components/footer/FooterContainer';
import HeaderContainer from '../new-components/layout/header/HeaderContainer';
import { deviceDetect } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { ConfigsApis } from '../new-lib/apis/configs.apis';

interface NotFoundProps {
  layout: {
    footerLinks: any;
  };
}

const NotFound = ({ layout: { footerLinks } }: NotFoundProps) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(deviceDetect(window.navigator.userAgent).isMobile);
  }, []);

  const { t } = useTranslation(['common']);

  return (
    <Container>
      <div className="layout-selector">
        <HeaderContainer forceMobile={isMobile} />
      </div>
      <div className="app-wrapper">
        <ErrorContainer>
          <Image
            src="/assets/icons/common/404.svg"
            alt="NoLongerAvailableIcon"
            priority
            width={259}
            height={205}
          />
          <h1>{t('404_main_header')}</h1>
          <p>{t('404_sub_header')}</p>
          <Button
            backgroundColor=""
            border="none"
            color=""
            fontSize=""
            text={t('Home')}
            onClick={() => {
              router.push({
                pathname: '/'
              });
            }}
            padding="0px 0px"
            btnClass="error-btn"
          />
        </ErrorContainer>
      </div>
      <div className="layout-selector footer-container">
        <FooterContainer footerLinks={footerLinks} forceMobile={isMobile} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    .footer-container {
      background: #e4ebf3;
      padding: 16px 30px 16px 16px;
    }
    .footer-container > :last-child {
      background: none;
      box-shadow: none;
    }
  }
`;

const ErrorContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: #00324c;
    font-size: 24px;
    font-family: materialBold;
    margin-top: 21px;
    margin-bottom: 4px;
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      font-size: ${props => props.theme.font.sizes.xl};
    }
  }
  p {
    font-size: ${props => props.theme.font.sizes.l};
    color: #212427;
    font-family: materialLight;
    margin-bottom: 8px;
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      font-size: ${props => props.theme.font.sizes.s};
    }
  }

  .error-btn {
    background: ${props => props.theme.light.colors.primaryBlue};
    border-radius: ${props => props.theme.borderRadius};
    border: none;
    width: 162px;
    height: 40px;
    color: ${props => props.theme.light.colors.white};
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.l};
    @media only screen and (min-width: ${props =>
        props.theme.breakPoints.desktop.min}) {
      &:hover {
        background: ${props => props.theme.light.hoverColors.primaryBlue};
      }
    }
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      width: 143.13px;
    }
  }
`;

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const [footerLinks, configVariables, filterOptions] = await Promise.all([
    getFooterLinks(locale, '404'),
    ConfigsApis.getConfigs(locale),
    getFilterOptions(locale)
  ]);

  return {
    revalidate: 60 * 60 * 24,
    props: {
      ...(await serverSideTranslations(locale as string, ['common'])),
      filterOptions: JSON.stringify(filterOptions) || '',
      configVariables,
      layout: {
        url: `${process.env.NEXT_PUBLIC_HOST}/404`,
        footerLinks
      }
    }
  };
};

export default NotFound;
